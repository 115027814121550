import React from "react"
import { Columns, Section } from "../../components/Containers"
import { Text } from "../../components/Core"

function OurMission(props) {
  const { sideColumnIs } = props
  return (
    <Section className="our-mission" smallSpacing>
      <Columns sideColumnsSize={sideColumnIs}>
        <div className="column px-40--mobile">
          <Text text={props.post.ourMission} />
        </div>
      </Columns>
    </Section>
  )
}

export default OurMission
